// extracted by mini-css-extract-plugin
export var antSteps = "article-module--ant-steps--Rh+uw";
export var antStepsDot = "article-module--ant-steps-dot--o-wOK";
export var antStepsHorizontal = "article-module--ant-steps-horizontal--y4TZw";
export var antStepsItemDescription = "article-module--ant-steps-item-description--VwbQj";
export var antStepsItemIcon = "article-module--ant-steps-item-icon--ET2wl";
export var antStepsItemTitle = "article-module--ant-steps-item-title--zYsHh";
export var antStepsSmall = "article-module--ant-steps-small--PMYin";
export var antTypography = "article-module--ant-typography--IdVa2";
export var author = "article-module--author--q0wzk";
export var hideOnReducedMotion = "article-module--hideOnReducedMotion--p6FFE";
export var link = "article-module--link--LSQ6l";
export var noBullet = "article-module--noBullet--qpXrj";
export var showOnReducedMotion = "article-module--showOnReducedMotion--cpmGy";
export var title = "article-module--title--7TdOU";
export var tweetBox = "article-module--tweet-box--Iaimr";