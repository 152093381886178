module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145425527-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Armeria","short_name":"Armeria","start_url":"/","lang":"en","display":"browser","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5d7ae48fc57b82138b93791a4afb4569"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/armeria/armeria/site/src/layouts/base.tsx","community":"/home/runner/work/armeria/armeria/site/src/layouts/community.tsx","docs":"/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx","news":"/home/runner/work/armeria/armeria/site/src/layouts/news.tsx","release-notes":"/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx","tutorials":"/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx"},"remarkPlugins":[null,null],"gatsbyRemarkPlugins":["gatsby-remark-autolink-headers",{"resolve":"/home/runner/work/armeria/armeria/site/src/plugins/gatsby-remark-api-links.js"},"gatsby-remark-copy-linked-files",{"resolve":"/home/runner/work/armeria/armeria/site/src/plugins/gatsby-remark-draw-patched.js","options":{"strategy":"img","mermaid":{"theme":"neutral","backgroundColor":"transparent"}}},{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-plantuml-lite","options":{"imageType":"svg"}},{"resolve":"/home/runner/work/armeria/armeria/site/src/plugins/gatsby-remark-table-of-contents-patched.js"}],"extensions":[".mdx"],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/armeria/armeria/site","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff0089"},
    }]
